<template>
  <div>
    <!-- banner start -->
    <section class="banner_sec wellness">
      <div class="banner_hed">
        <h1 class="heading_2">Get Your Wellness On!</h1>
      </div>
    </section>

    <!-- heading start -->
    <section class="bg_grey heading_sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="heading_box webinars_heading_banner">
              <h3 class="heading_3">
                Do you want your employees to be free of stress? <br>
                How about energized and focused throughout the day? <br>
                We thought you might feel that way.
              </h3>
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="learn_more_btn text-center upMar">
              <button
                v-scroll-to="{
                  el: '#why-choose1',
                  easing: 'linear',
                  offset: -230,
                }"
                class="no-border"
              >
                <img src="../../assets/home/images/down_arrow.png" alt="img" />
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <!-- heading end -->

    <!-- banner end -->

    <!-- right left text start -->
     <section class="right_left_text_sec padding_100 wellness_serise" > <!-- id="why-choose1" -->
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-12">
              <h4 class="vdo-section-text">PBE is Proud to Introduce Our New Wellness Webinar Series</h4>              
          </div>
          <div class="col-sm-6 video-library">
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="text_black"
            >
              <div class="video_box">
                <img
                  src="../../assets/home/images/wellness_series/wellness-video-bg.png"
                  alt="img"
                  class="free_img"
                />
                <span class="justify-content-center">
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  New Wellness Series
                </span>
              </div>
            </a>
          </div>
          <div
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            class="modal fade meet_our_team_modal free_resources_form"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header border-bottom-0">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="hideModal"
                  ></button>
                </div>
                <div class="modal-body">
                  <div style="padding: 56.25% 0 0 0; position: relative">
                    <iframe
                      id="closed"
                      src="https://player.vimeo.com/video/1040037413?h=e5b5420c23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      "
                      title="Introducing PBE's Wellness Series"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
              <h4 class="vdo-section-text p-50-66">A Mix and Match Series of 30-minute Webinars Designed to Make Everyone Feel Their Best</h4>              
          </div>
        
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-xl-5 virtual-eve">
            <div class="right_text_virtual">
              <h5 class="heading_5">Why does wellness matter?</h5>
              <p>
                With so many of us working just about any and everywhere, employees are facing new stresses and overtaxing themselves. And that is not benefiting your employees or your organization! Your employees are your most important asset, so taking care of them must be a priority!
              </p>
            </div>
          </div>
          <div class="col-md-2 col-xl-1 virtual-arrow">
            <div class="web_arrow">
              <img
                src="../../assets/home/images/webinars/arrow.svg"
                alt="img"
              />
            </div>
          </div>
          <div class="col-md-5 col-xl-6 evaluations-include">
            <div class="right_text_virtual virtual_right_box">
              <h5 class="heading_5">How does this work?</h5>
              <p>Each Wellness Webinar is 30 minutes in length and can be offered as a stand-alone event or as part of a series! They are perfect for:</p>             
              <ul class="m-0 pl_22 bigscreen">
                <li>
                  <p>Team Meetings</p>
                </li>
                <li>
                  <p>Company All-Hands Events</p>
                </li>
                <li>
                  <p>Monthly or Quarterly Offerings</p>
                </li>
                <li>
                  <p>Company Benefits and Health Events</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="webinars_btn text-center mt_60">
              <router-link
                class="btn btn_primary px_82"
                :to="{ name: 'learn_more' }"
              >
                Schedule Today!
              </router-link>
            </div>
          </div>
          <div class="col-md-12">
              <p class="belowbtntext">If your folks are on site, we can be there too. Ask us about our seminar versions of the classes below!</p>              
          </div>
        </div>
      </div>
    </section>
    <!-- right left text end -->

    <!-- WFH start -->
    <section class="wfh_sec bg_grey hybrid_wfh padding_100">
      <div class="container">
        <!-- <div class="row">
          <div class="col-md-12">
            <div class="wfh_heading text-center">
              <h3 class="heading_3 text_black font_bold" id="getfour">
                Work From Home and Hybrid Webinars
              </h3>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div id="accordionExample" class="accordion d-flex">
            <div class="col-md-4 col-xl-3">
              <div class="wfh_box">
                <img src="../../assets/home/images/wellness_series/unplug_unwind.png" alt="img" />

                <div class="accordion-item" id="get_one">
                  <h2
                    id="headingOne"
                    class="accordion-header"
                    @click="scrollfour"
                  >
                    <button
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      class="accordion-button collapsed"
                    >
                      Unplug &<br />Unwind
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse"
                  >
                    <div class="accordion-body">
                      <p>
                        Between a mountain of  to-do lists, back-to-back meetings and never-ending  screentime, sometimes it feels impossible to unplug and catch your breath!  This fun and informative webinar will help you design strategies to carve out “screen-free- time” each day and, provide you with guided meditations and other unwinding techniques to regroup and recharge.
                      </p>
                      <h6>Topics Include:</h6>
                      <ul class="m-0 pl_20">
                        <li>Screen Free Strategies</li>
                        <li>Guided Meditations</li>
                        <li>Deep Breathing Exercises</li>
                        <li>Techniques to Quiet Your Mind</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-xl-3">
              <div class="wfh_box">
                <img src="../../assets/home/images/wellness_series/posture-perfect.png" alt="img" />
                <div class="accordion-item" id="get_two">
                  <h2
                    id="headingTwo"
                    class="accordion-header"
                    @click="scrollone"
                  >
                    <button
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      class="accordion-button collapsed"
                    >
                    Posture<br />
                    Perfect
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse"
                  >
                    <div class="accordion-body">
                      <p>
                        Nothing can take you off your ergo-game more than  poor postures that compromise your comfort and well-being.  Working within healthy postures helps to stave off those unwanted aches and pains, elevates your mood and will give your overall physical and mental health a much needed boost!
                      </p>
                      <h6>Topics Include:</h6>
                      <ul class="m-0 pl_20">
                        <li>Sitting and Standing Lessons</li>
                        <li>Neck and Shoulder Exercises</li>
                        <li>Back Stretches</li>
                        <li>Core and Leg Strengthening</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-xl-3">
              <div class="wfh_box">
                <img src="../../assets/home/images/wellness_series/ergocises.png" alt="img" />
                <div class="accordion-item" id="get_three">
                  <h2
                    id="headingThree"
                    class="accordion-header"
                    @click="scrolltwo"
                  >
                    <button
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      class="accordion-button collapsed"
                    >
                    Ergo-cises<br /> At Your Desk
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse"
                  >
                    <div class="accordion-body">
                      <p>
                        Recently, you may have heard conflicting expert opinions:  “Sitting at your desk is  not good for you”   “Standing for too long comes with health hazards!" Whether sitting or standing, and we recommend switching it up,  these simple but effective  exercises, designed to do at your desk, will have you  feeling fit in no time at all!
                      </p>
                      <h6>Topics Include Exercises for Your</h6>
                      <ul class="m-0 pl_20">
                        <li>Neck & Shoulders</li>
                        <li>Arms</li>
                        <li>Core and Back</li>
                        <li>Legs</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-xl-3">
              <div class="wfh_box">
                <!-- <p class="home-s_new">New !</p> -->
                <img
                  src="../../assets/home/images/wellness_series/the-nack-up.png"
                  alt="img"
                />
                <div class="accordion-item">
                  <h2
                    id="headingFour"
                    class="accordion-header"
                    @click="scrollthree"
                  >
                    <button
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      class="accordion-button collapsed"
                    >
                    Unburden<br> Your Neck & Shoulders
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                    class="accordion-collapse collapse"
                  >
                    <div class="accordion-body">
                      <p>
                        What with multi-tasking, multiple screens and the pressure to perform at a high level, it’s safe to say that the muscles in your neck and shoulders can get pretty stressed out over the course of your workday. This interactive training  will show you how to both avoid work overload as well as how to treat your neck and shoulders when they are tight and uncomfortable.
                      </p>
                      <h6>Topics Include:</h6>
                      <ul class="m-0 pl_20">
                        <li>Common Neck and Shoulder Ergo Traps</li>
                        <li>Neck and Shoulder Stretches</li>
                        <li>Strengthening Exercises</li>
                        <li>Stress Release Techniques</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- last div -->
          </div>
        </div>
      </div>
    </section>
    <!-- WFH end -->

  </div>
</template>

<script>
import store from "@/store/index";
import $ from "jquery";
export default {
  name: "wellness_series",
  methods: {
    hideModal() {
      document.getElementById("closed").src += "";
    },
    scrollone() {
      setTimeout(function () {
        const mediaQuery = window.matchMedia("(max-width: 767px)");
        if (mediaQuery.matches) {
          $("html,body").animate(
            {
              scrollTop: $("#get_one").offset().top,
            },
            "slow"
          );
        }
      }, 500);
    },
    scrolltwo() {
      setTimeout(function () {
        const mediaQuery = window.matchMedia("(max-width: 767px)");
        if (mediaQuery.matches) {
          $("html,body").animate(
            {
              scrollTop: $("#get_two").offset().top,
            },
            "slow"
          );
        }
      }, 500);
    },
    scrollthree() {
      setTimeout(function () {
        const mediaQuery = window.matchMedia("(max-width: 767px)");
        if (mediaQuery.matches) {
          $("html,body").animate(
            {
              scrollTop: $("#get_three").offset().top,
            },
            "5000"
          );
        }
      }, 500);
    },
    scrollfour() {
      const mediaQuery = window.matchMedia("(max-width: 767px)");
      if (mediaQuery.matches) {
        $("html,body").animate(
          {
            scrollTop: $("#getfour").offset().top,
          },
          "slow"
        );
      }
    },
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    store.dispatch("Home/getHomeData").then(() => {});
  },
};
</script>
